<template>
  <div :class="['side-menu', { 'menu-open': menuOpen, 'menu-closing': isClosing }]">
    <button class="back-button" @click="closeMenu">&larr;</button>
    <nav>
      <ul>
        <!-- Exibindo marcas -->
        <li v-for="brand in brands" :key="brand.id" class="brand-link">
          <a href="#" @click.prevent="handleBrandSelection(brand)">{{ brand.nome }}</a>
        </li>

        <!-- Marca XO destacada -->
        <li class="brand-link">
          <a href="#" @click.prevent="selectXoBrand">XO</a>
        </li>

        <!-- Lista de categorias e departamentos abaixo -->
        <li v-for="category in departments" :key="category.id" class="menu-item-has-children">
          <a href="#" @click.prevent="selectCategory(category)" class="category-link">{{ category.name }}</a>
          <ul class="sub-menu">
            <li v-for="department in category.departments" :key="department.id">
              <a href="#" @click.prevent="selectDepartment(department, category)" class="department-link">{{ department.name }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import { mapState } from 'vuex';

export default {
  name: 'MobileMenu',
  props: {
    menuOpen: Boolean
  },
  data() {
    return {
      brands: [],
      departments: [],
      isClosing: false
    };
  },
  async created() {
    try {
      // Carregar categorias e departamentos
      const categoriesResponse = await axios.get('https://api-genove.agcodecraft.com/api/public/categories');
      this.departments = categoriesResponse.data.map(category => ({
        ...category,
        slug: category.slug,
        departments: category.departments ? [...category.departments].sort((a, b) => a.name.localeCompare(b.name)) : []
      })).sort((a, b) => a.name.localeCompare(b.name));

      // Carregar marcas
      const brandsResponse = await axios.get('https://api-genove.agcodecraft.com/api/public/brands');
      this.brands = brandsResponse.data.filter(brand => ['APPLE', 'XIAOMI', 'HONOR'].includes(brand.nome));

    } catch (error) {
      console.error('Erro ao buscar categorias e marcas:', error);
    }
  },
  methods: {
    ...mapActions(['fetchCategories', 'fetchBrands']),
    
    closeMenu() {
      this.isClosing = true;
      setTimeout(() => {
        this.$emit('toggle-menu');
        this.isClosing = false;
      }, 300); // Aguardar tempo suficiente para a animação
    },
    handleBrandSelection(brand) {
      this.selectBrand(brand);
      this.closeMenu();
    },
    selectCategory(category) {
      this.$router.push({ name: 'produtos', params: { categoriaSlug: category.slug } });
      this.closeMenu(); // Fechar o menu após selecionar uma categoria
    },
    selectDepartment(department, category) {
      this.$router.push({
        name: 'produtos',
        params: {
          categoriaSlug: category.slug,
          departamentoSlug: department.slug
        }
      });
      this.closeMenu(); // Fechar o menu após selecionar um departamento
    },
    selectBrand(brand) {
      this.$router.push({ name: 'marca', params: { brandSlug: brand.slug } });
      this.closeMenu(); // Fechar o menu após selecionar uma marca
    },
    selectXoBrand() {
      this.$router.push({
        name: 'marca',
        params: {
          brandSlug: 'xo'
        }
      });
      this.closeMenu(); // Fechar o menu após selecionar a marca XO
    }
  }
};
</script>

<style scoped>
.side-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 90%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  transition: left 0.3s ease;
  z-index: 1000;
  color: #333;
}

.side-menu.menu-open {
  left: 0;
}

.side-menu.menu-closing {
  left: -100%;
}

.back-button {
  background: none;
  border: none;
  font-size: 32px;
  color: #333;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.side-menu nav {
  padding: 80px 20px 20px 20px;
}

.side-menu ul {
  list-style: none;
  padding: 0;
}

.side-menu ul li {
  margin-bottom: 10px;
  position: relative;
}

.brand-link a {
  text-decoration: none;
  color: #C99C5A;
  font-size: 18px;
  font-weight: bold;
  display: block;
  padding: 10px 15px;
  background-color: rgba(201, 156, 90, 0.2);
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.brand-link a:hover {
  background-color: #C99C5A;
  color: #fff;
}

.category-link {
  text-decoration: none;
  color: #333;
  font-size: 20px;
  font-weight: bold;
  display: block;
  padding: 10px 15px;
  background-color: rgba(201, 156, 90, 0.1);
  border-left: 4px solid #C99C5A;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.category-link:hover {
  background-color: #C99C5A;
  color: #fff;
}

.department-link {
  text-decoration: none;
  color: #666;
  font-size: 16px;
  display: block;
  padding: 8px 20px;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 5px;
  margin-left: 15px;
}

.department-link:hover {
  background-color: rgba(201, 156, 90, 0.2);
  color: #333;
}

.side-menu .sub-menu {
  padding-left: 0;
  display: none;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 0;
}

.menu-item-has-children:hover .sub-menu {
  display: block;
  max-height: 200px;
}

.menu-item-has-children a::after {
  content: '▼';
  position: absolute;
  right: 10px;
  font-size: 12px;
  transition: transform 0.3s;
}

.menu-item-has-children:hover a::after {
  transform: rotate(-180deg);
}
</style>
