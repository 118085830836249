<template>
  <section class="custom-hero-slider-container">
    <!-- Indicadores -->
    <div class="custom-carousel-indicators">
      <button
        v-for="(slide, index) in slides"
        :key="index"
        :class="{ active: index === currentSlide }"
        @click="goToSlide(index)"
        :aria-label="'Slide ' + (index + 1)"
      ></button>
    </div>

    <!-- Slides -->
    <div class="custom-carousel-inner" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        class="custom-carousel-item"
        @click="navigateToBrand(slide.brandId)"
      >
        <img :src="slide.backgroundImage" alt="" class="custom-carousel-image" />
      </div>
    </div>

    <!-- Controles -->
    <button class="custom-carousel-control-prev" @click="prevSlide">
      &#10094;
    </button>
    <button class="custom-carousel-control-next" @click="nextSlide">
      &#10095;
    </button>
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const currentSlide = ref(0);
const slides = ref([
  {
    backgroundImage: require('@/assets/images/banner03.png'),
    brandId: 5 
  },
  {
    backgroundImage: require('@/assets/images/banner02.jpg'),
    brandId: 6 
  },
  {
    backgroundImage: require('@/assets/images/banner01.jpg'),
    brandId: 3 
  }
]);

const goToSlide = (index) => {
  currentSlide.value = index;
};

const nextSlide = () => {
  currentSlide.value = (currentSlide.value + 1) % slides.value.length;
};

const prevSlide = () => {
  currentSlide.value = (currentSlide.value - 1 + slides.value.length) % slides.value.length;
};

const navigateToBrand = (brandId) => {
  if (brandId === 5) { 
    router.push({ name: 'produtos', query: { text: 'iphone 16' } });
  } else {
    router.push({ name: 'produtos', query: { brand: brandId } });
  }
};

onMounted(() => {
  const interval = setInterval(nextSlide, 5000);
  return () => clearInterval(interval);
});
</script>


<style scoped>
.custom-hero-slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  cursor: pointer
}

.custom-carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.custom-carousel-item {
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.custom-carousel-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.custom-carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
}

.custom-carousel-indicators button {
  width: 12px;
  height: 12px;
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-carousel-indicators .active {
  background-color: rgba(255, 255, 255, 1);
}

.custom-carousel-control-prev,
.custom-carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 2rem;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease;
}

.custom-carousel-control-prev {
  left: 10px;
}

.custom-carousel-control-next {
  right: 10px;
}

.custom-carousel-control-prev:hover,
.custom-carousel-control-next:hover {
  color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 992px) {
  .custom-carousel-item {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

@media (max-width: 768px) {
  .custom-carousel-item {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

@media (max-width: 576px) {
  .custom-carousel-item {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
</style>
